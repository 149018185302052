<style scoped>
.callStatusChip {
    display: inline-block;
    text-align: center;
    width: 125px;
}
</style>
<template>
    <v-card outlined v-if="isLoaded">
        <v-card-title class="pt-8 pl-8 pr-8 pb-0">
            <v-row class="pl-0 pb-5">
                <v-col class="pl-0"><span class="text-h6" style="color:#333333;">콜리 현황</span></v-col>
                <v-col class="pt-0 text-right">
                    <v-btn outlined large rounded class="text-h6" color="grey" @click="goStaffList"><span style="color:#333333">관리하기</span></v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-card outlined tile>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                                    <th class="text-left text-h6 pl-12">
                                        이름
                                    </th>
                                    <th class="text-center text-h6" style="width:300px;">
                                        상태
                                    </th>
                                </tr>
                                </thead>
                                <tbody style="color:#333333;">
                                <template v-for="(staffItem, staffIdx) in staffsData">
                                    <tr style="height:68px;" :key="staffIdx" v-if="staffIdx < 6">
                                        <td class="text-h6 pl-12">{{ staffItem.staff_name }}</td>
                                        <td class="text-center">
                                            <v-chip v-if="staffItem.call_status_type == 'OFF_TIME'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#E66363"
                                                    text-color="white">부재중
                                            </v-chip>
                                            <v-chip v-else-if="staffItem.call_status_type == 'OFF_WORK'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#E66363"
                                                    text-color="white">업무종료
                                            </v-chip>
                                            <v-chip v-else-if="staffItem.call_status_type == 'CALLING'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#F0B14A"
                                                    text-color="white">통화중
                                            </v-chip>
                                            <v-chip v-else-if="staffItem.call_status_type == 'READY'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#87C720"
                                                    text-color="white">통화가능
                                            </v-chip>
                                            <v-chip v-else-if="staffItem.call_status_type == 'UNKNOWN' && staffItem.app_reg_yn == 'N'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip"
                                                    label color="#777777" text-color="white">콜리 미등록
                                            </v-chip>
                                            <v-chip v-else class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#87C720" text-color="white">알수없음</v-chip>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>

                <v-col v-if="staffsData && staffsData.length > 6">
                    <v-card outlined>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                                    <th class="text-left text-h6 pl-12">
                                        상담원 이름
                                    </th>
                                    <th class="text-center text-h6" style="width:300px;">
                                        상태
                                    </th>
                                </tr>
                                </thead>
                                <tbody style="color:#333333;">
                                <template v-for="(staffItem, staffIdx) in staffsData">
                                    <tr style="height:68px;" :key="staffIdx" v-if="staffIdx >= 6 && staffIdx < 12">
                                        <td class="text-h6 pl-12">{{ staffItem.staff_name }}</td>
                                        <td class="text-center">
                                            <v-chip v-if="staffItem.call_status_type == 'OFF_TIME'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#E66363"
                                                    text-color="white">부재중
                                            </v-chip>
                                            <v-chip v-if="staffItem.call_status_type == 'CALLING'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#F0B14A"
                                                    text-color="white">통화중
                                            </v-chip>
                                            <v-chip v-if="staffItem.call_status_type == 'READY'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label color="#87C720" text-color="white">
                                                통화가능
                                            </v-chip>
                                            <v-chip v-if="staffItem.call_status_type == 'UNKNOWN' && staffItem.app_reg_yn == 'N'" class="ma-5 text-subtitle-1 font-weight-bold callStatusChip" label
                                                    color="#777777" text-color="white">콜리 미등록
                                            </v-chip>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'dashboard_tile_staffs',
    components: {},
    data: () => ({}),
    created() {
    },
    computed: {
        ...mapGetters({
            staffsData: 'dashboard/getStaffList',
        }),
        isLoaded() {
            if (this.staffsData) {
                return true
            }
            return false
        }
    },
    methods: {
        goStaffList() {
            this.$router.push('/svc/staff/list')
        }
    }
}
</script>
