<template>
    <v-card outlined v-if="isLoaded">
        <v-card-title class="pt-8 pl-8 pr-8 pb-0">
        <v-row class="pl-0 pb-5">
            <v-col class="pl-0"><span class="text-h6" style="color:#333333;">Today</span> <span class="text-body-1 ml-5 mt-2">{{statData.today | dateMin}}</span></v-col>
            <v-col class="pt-0 text-right">
            <v-btn outlined large rounded class="text-h6" color="grey" @click="goStat"><span style="color:#333333">통계보기</span></v-btn>
            </v-col>
        </v-row>
        </v-card-title>
        <v-card-text>
        <v-row>
            <v-col class="pt-0" style="min-width:500px;">                
                <v-row>
                    <v-col>
                        <v-card flat style="background-color:#4D9BBE;">
                            <v-card-text class="pt-7 pb-0 white--text text-h6">
                            응답률
                            </v-card-text>                      
                            <v-card-text class="pt-0 pb-7 white--text text-right">
                            <span class="text-h3 font-weight-bold">{{statData.answer_ratio}}</span><span class="ml-2 text-h5">%</span>
                            </v-card-text>                      
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat style="background-color:#57AFAF;">
                            <v-card-text class="pt-7 pb-0 white--text text-h6">
                            콜백완료
                            </v-card-text>
                            <v-card-text class="pt-0 pb-7 white--text text-right">
                            <span class="text-h3 font-weight-bold">{{statData.callback_processed_count}}</span><span class="ml-2 text-h5">건</span>
                            </v-card-text>                      
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat style="background-color:#6891D3;">
                            <v-card-text class="pt-7 pb-0 white--text text-h6">
                            콜백대기
                            </v-card-text>
                            <v-card-text class="pt-0 pb-7 white--text text-right">
                            <span class="text-h3 font-weight-bold">{{statData.callback_unprocessed_count}}</span><span class="ml-2 text-h5">건</span>
                            </v-card-text>                      
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col style="min-width:500px;">
                <v-card outlined>
                    <v-card-text>
                    <v-row>
                        <v-col class="pb-0">
                        <p class="text-h6 text-center">총 수신전화</p>
                        <p class="text-center"><span class="text-h5">{{statData.total_received_call_count}}</span> <span>건</span></p>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pb-0">
                        <p class="text-h6 text-center">콜리 연결</p>
                        <p class="text-center"><span class="text-h5">{{statData.processed_call_count}}</span> <span>건</span></p>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pb-0">
                        <p class="text-h6 text-center">포기전화</p>
                        <p class="text-center"><span class="text-h5">{{statData.forgiven_call_count}}</span> <span>건</span></P>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="pb-0">
                        <p class="text-h6 text-center">콜백요청</p>
                        <p class="text-center"><span class="text-h5">{{statData.callbacked_call_count}}</span> <span>건</span></p>
                        </v-col>  
                    </v-row>                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-card-text>
    </v-card> 
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'dashboard_tile_stats',
    components: {
    },
    data: () => ({
    }),
    created () { 
    },
    computed: {
        ...mapGetters({
            statData: 'dashboard/getStat',
        }),
        isLoaded () { 
            if(this.statData) { return true }
            return false
        }
    },  
    methods : { 
        goStat() { 
            this.$router.push("/stats")
        }
    }
}
</script>