<template>
    <v-container class="py-8 px-6 pl-12" fluid>
        <v-card outlined style="border: 0px solid white" class="mt-n10">
            <v-card-text class="mt-n3">
                <v-row>
                    <v-col cols="12"><p class="cont-title">{{ companyName }} <span v-if="svcMdn">({{ svcMdn | phoneNum }})</span></p></v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row>
            <v-col cols="12">
                <tile-stats></tile-stats>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <tile-staff-list></tile-staff-list>
            </v-col>
        </v-row>

        <v-dialog v-model="modal" persistent max-width="850" @keydown.esc="closeNoticePopup()">
            <v-flex v-if="dialogMode === 'notice'">
                <notice-popup v-on:cancel="closeNoticePopup"></notice-popup>
            </v-flex>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapGetters, mapState} from 'vuex'

import TileStats from '@/components/dashboard/tile_stats.vue'
import TileStaffList from '@/components/dashboard/tile_staff_list.vue'

export default {
    name: 'dashboard',
    components: {
        TileStats, TileStaffList,
        'noticePopup': () => import('@/components/dashboard/popup_notice'),
        //  HelloWorld
    },
    data: () => ({
        modal: false,
        dialogMode: 'none'
    }),
    computed: {
        ...mapGetters({
            companyName: 'login/getCompanyName',
            svcMdn: 'login/getSvcMdn',
        }),
        ...mapState('login', {
            noticeAlertFlag: state => state.noticeAlertFlag,
            notice: state => state.notice,
        }),
    },
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'dashboard')

        this.$store.dispatch('dashboard/getInfo', {})

        if (this.notice) {
            console.log('create() : cookie=', this.$cookies.get('call_admin_notice_' + this.notice.notice_seqno))
        }

        if (this.notice && this.notice.notice_title && this.$cookies.get('call_admin_notice_' + this.notice.notice_seqno) != 'CHECKED') {
            this.openNoticePopup()
        }

    },
    mounted() {
    },
    methods: {
        openNoticePopup(scb) {
            this.modal = true
            this.dialogMode = 'notice'

            if (scb) {
                this.dialogScb = scb
            }
        },
        closeNoticePopup(param) {
            if (param && param.dont_show_today != null && param.dont_show_today != undefined) {
                if (param.dont_show_today) {
                    const today = new Date()
                    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
                    tomorrow.setDate(tomorrow.getDate() + 1)
                    this.$cookies.set('call_admin_notice_' + this.notice.notice_seqno, 'CHECKED', tomorrow.toGMTString())
                }
            }
            this.modal = false

            if (this.dialogScb) {
                this.dialogScb()
            }
            this.dialogScb = null
        },
    }
}
</script>
<style>
</style>
